<template>
  <div>
    <div class="container-fluid">
      <PageTitle />

      <template v-if="isList">
        <ul class="nav nav-tabs customtab" role="tablist">
          <li class="nav-item"> <a class="nav-link active" id="main-nav-dynamic" data-toggle="tab" href="#main" role="tab" @click="changeTab('main')"><span
                class="hidden-sm-up"></span> <span class="hidden-xs-down">Services</span></a> </li>
          <li class="nav-item"> <a class="nav-link" id="seo-nav-dynamic" data-toggle="tab" href="#seo" role="tab" @click="changeTab('SEO')"><span
                class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO Setting</span></a> </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane fade show active" id="main" role="tabpanel">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title mb-0">Hero Image</h5>
                <small>Last updated : {{(row.ahi_update_date||"").dates("format")}}</small>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="wrap_slider_img">
                      <div class="bullet-cta">
                        <a href="javascript:;" @click="openHero()" v-tooltip="'Edit Hero Image'" class="bullet_edit"><i
                            class="fas fa-pencil-alt"></i></a>
                      </div>
                      <img
                        :src="uploader((row.ahi_background || '').removeWebp(),'411')"
                        class="img-responsive" />
                      <div class="slider_name">
                        <p># {{row.ahi_title}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <ValidationObserver v-if="isHero" v-slot="{ handleSubmit }" ref="VFormHero">
                  <form @submit.prevent="handleSubmit(doSubmitHero)">
                    <div class="row mt-4">
                      <div class="col-md-8 mb-3">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="panel_group">
                              <div class="panel_heading">Hero Image Text</div>
                              <div class="panel_body">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Title <span class="text-danger">*</span></label>
                                      <input v-model="row.ahi_title" type="text" id="firstName" class="form-control"
                                        placeholder="Page Title">
                                      <VValidate name="Title" v-model="row.ahi_title" rules="required" />
                                    </div>
                                  </div>
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Description</label>
                                      <textarea class="form-control" v-model="row.ahi_description" rows="5"
                                        placeholder="Page Description">
                                      </textarea>
                                      <VValidate name="Description" v-model="row.ahi_description"
                                        rules="required" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <Uploader v-model="row.ahi_background" type="hero" label="Hero Image" />
                        <VValidate name="Hero Image" v-model="row.ahi_background" rules="required" />
                        <div class="form-group mt-3">
                          <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                          <input type="text" v-model="row.ahi_alt" class="form-control" placeholder="Alt Image">
                          <VValidate name="Alt Image Hero" v-model="row.ahi_alt" rules="required" />
                        </div>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-end">
                      <div class="col-4 mt-3">
                        <button type="submit" class="btn btn-rounded btn-block btn-info">Submit</button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
            <!--card -->
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-md-9">
                    <h5 class="card-title mb-0">Our Services</h5>
                  </div>
                  <div class="col-md-3 text-right">
                    <div class="btn-group" @click="dosetStatus()" role="group" aria-label="Basic example">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8"></div>
                  <div class="col-md-4">
                    <form @submit.prevent="doFilter(),doSearch()">
                      <div class="form-group mb-0">
                        <div class="input-group">
                          <input v-model="filter.search" type="text" class="form-control" @input="checkDisabled"
                            placeholder="Type and Press Enter" />
                          <div class="input-group-append">
                            <button class="btn btn-info" disabled type="submit" id="btn-search"><i class="fas fa-search"></i></button>
                          </div>
                          <button v-if="isSearch" @click="doReset(),clsSearch()" type="button"
                            class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <draggable class="row mt-4 gutter-20" v-model="data.data" v-if="(data.data||[]).length > 0" @start="startDrag" @end="endDrag" v-bind="dragOptions" >
                  <div class="col-md-6" v-for="(v,k) in data.data" :key="k">
                    <div v-tooltip="v.as_update_date ? 'Last updated : ' + v.as_update_date.dates('format2') : 
                    'Created : ' + v.as_create_date.dates('format2')" :class="data.data.length > 1 ? 'pointer wrap_service' : 'wrap_service'">
                      <div class="row">
                        <div class="col-md-7 order-1 order-md-0">
                          <div class="wrap_service_post">
                            <h3>{{ v.as_title }}</h3>
                            <p>{{ v.as_short_desc }}</p>
                            <span 
                              class="label"
                              :class="v.as_is_active === 'Y' ?'label-success' : 'label-danger'"
                            >{{ v.as_is_active === "Y" ? "Active" : "Inactive" }}</span>
                          </div>
                        </div>
                        <div class="col-md-5 mb-3 mb-md-0">
                          <div class="wrap_ic"
                            :style="`background-color: ${v.as_logo_back_color == 'Purple' ? '#4d2668' : '#96be50'};`">
                            <img :src="v.as_logo === '' ? baseUrl+'/img/no-image.jpg' : apiUrl + '/uploader/' + (v.as_logo || '').removeWebp()" />
                          </div>
                        </div>
                      </div>
                      <div class="bullet-cta">
                        <router-link :to="{name:$route.name, params:{pageSlug:v[idKey]}}" role="button"
                          class="bullet_edit finger mr-2" v-tooltip="'Edit Content'"><i class="fas fa-pencil-alt"></i></router-link>
                        <a v-tooltip="'Change Status'" @click.prevent="doChangeStatus(k,v)" class="bullet_edit finger mr-2"><i
                            class="fa fa-cog"></i></a>
                        <a @click.prevent="doDelete(v.as_id, v)" v-tooltip="'Delete'" class="bullet_delete finger"><i
                            class="far fa-trash-alt"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="data.data===false">
                    <Loader class="mt10 mb10" />
                  </div>
                </draggable>

                <div class="col-md-12 text-center pt-4 pb-4" v-if="data.data.length <= 0">
                  <h4>{{ isSearch ? 'Service Not Found' : 'Service Not Available' }}</h4>
                </div>
              </div>
              <div class="card-footer" v-if="data.data!==false&&data.data.length">
                <div class="row">
                  <div class="col-sm-6"></div>
                  <div class="col-sm-6">
                    <div class="pagination-flat float-right">
                      <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                        <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                        <span slot="next-nav"><i class="icon-arrow-right"></i></span>
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Call to action section</h5>
                <small>Last Updated : {{(sectionUpdateDate||"").dates('format2')}}</small>
              </div>
              <ValidationObserver v-slot="{ handleSubmit }" ref="VFormSection">
                <form @submit.prevent="handleSubmit(doSubmitSection)">
                  <div class="card-body">
                    <div class="row">
                      <!-- Column -->
                      <div class="col-lg-6 col-md-6">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Title <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="section.title"
                                placeholder="Title" />

                              <VValidate name="Section Title" v-model="section.title" rules="required" />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Button Label <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="section.button_label"
                                placeholder="Contact Us Now" />

                              <VValidate name="Button Label" v-model="section.button_label" rules="required" />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label class="control-label">Button Link <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" v-model="section.button_link"
                                placeholder="/contact" />

                              <VValidate name="Button Link" v-model="section.button_link" rules="required" />
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="col-md-6">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Description <span class="text-danger">*</span></label>
                            <div class="input-group">
                              <textarea  type="text" rows="10" class="form-control"
                                placeholder="Description" v-model="section.desc"></textarea>
                            </div>
                            <VValidate name="Section Description" v-model="section.desc" rules="required" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card-footer">
                    <div class="form-actions text-right">
                      <button type="submit" class="btn btn-rounded btn-info">Submit</button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
          <div class="tab-pane fade" id="seo" role="tabpanel">
            
            <div class="card" v-if="isMeta">
              <div class="card-body pb-0">
                <div class="form-group row align-items-center mb-0">
                  <label for="" class="col-form-label col-auto">Auto-fill SEO?</label>
                  <div class="col-md">
                    <div class="ia-switch" @click.stop="editAutoFill">
                      <input type="checkbox" v-model="isCheck" name="toggleAutoSeo" id="toggleAutoSeo" class="ia-switch-input">
                      <label for="toggleAutoSeo" class="ia-input-label"></label>
                    </div>
                  </div>
                </div>
              </div>
              <ValidationObserver v-slot="{ handleSubmit }" ref="VFormSeo">
                <form v-on:submit.prevent="handleSubmit(doSubmitSeo)">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label">Meta Title <a
                              v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                              class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                          <input v-model="rowSEO.sss_title" @input="checkDisabledSeo" type="text" id="firstName" class="form-control"
                            placeholder="Meta Title">
                          <VValidate name="Meta Title" v-model="rowSEO.sss_title" :rules="{required: 1, max: 70}" />
                        </div>

                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="" class="control-label">
                            Meta Keyword
                            <a class="help_info" v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                              ?
                            </a>
                            <span class="text-danger mr5">*</span>
                          </label>

                          <input-tag v-model="rowSEO.sss_keyword"
                            placeholder="Type and press enter to Add"
                            @input="checkDisabledSeo">
                            
                          </input-tag>

                          <VValidate name="Keyword" v-model="rowSEO.sss_keyword" rules="required" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="control-label">Meta Description <a
                              v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                              class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                          <textarea v-model="rowSEO.sss_desc" class="form-control" rows="4" @input="checkDisabledSeo" ></textarea>

                          <VValidate name="Meta Description" v-model="rowSEO.sss_desc" :rules="{required: 1, max: 160}" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="form-actions text-right">
                      <button @click.prevent="doResetSEO()" id="button-cancel" type="button" class="btn  btn-rounded  btn-secondary" disabled>Cancel
                      </button>
                      <button type="submit" class="btn  btn-rounded  btn-info">Submit</button>
                    </div>
                  </div>

                </form>
              </ValidationObserver>
            </div>
            <div class="card" v-else>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 text-right">
                      <label class="text-primary">Last updated : {{(rowSEO.sss_update_date||"").dates('format2')}}</label>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Title <a
                            v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                            <p>{{rowSEO.sss_title}}</p>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="" class="control-label">
                          Meta Keyword
                          <a class="help_info"
                            v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                            ?
                          </a>
                          <span class="text-danger mr5">*</span>
                        </label>
                        <div></div>
                        <template v-for="(v,k) in rowSEO.sss_keyword" >
                          <label class="label label-success mr-1" :key="k">
                            {{v}}
                          </label>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Description <a
                            v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <p>{{rowSEO.sss_desc}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="form-actions text-right">
                    <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </template>
      <div class="row" v-else>
        <div class="col-lg-12">
          <div class="card">
          <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
            <form @submit.prevent="handleSubmit(doValidate)">
            <div class="row">
              <div class="col-md-12">
                <ul class="nav nav-tabs customtab" role="tablist">
                  <li class="nav-item"> <a class="nav-link active" @click="changeTab('mainForm')" id="main-nav" data-toggle="tab" href="#editor" role="tab"><span
                        class="hidden-sm-up"></span> <span class="hidden-xs-down">Services Form</span></a> </li>
                  <li class="nav-item"> <a class="nav-link"  @click="changeTab('SEOForm')" id="seo-nav" data-toggle="tab" href="#seo" role="tab"><span
                        class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO Setting</span></a> </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active" id="editor" role="tabpanel">                  
                    <!-- Hero Here -->
                    <div class="card">
                      <div class="card-header">
                        <div class="row">
                          <div class="col-md-9">
                            <h5 class="card-title mb-0">Hero Image</h5>
                          </div>
                          <div class="col-md-3 text-right">
                            <span></span>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row" v-if="!isAdd">
                          <div class="col-md-4">
                            <div class="wrap_slider_img">
                              <div class="bullet-cta">
                                <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
                                  aria-controls="collapseExample" v-tooltip="'Edit Hero Image'" class="bullet_edit collapse_btn"><i class="fas fa-pencil-alt"></i></a>
                              </div>
                              <img
                                :src="uploader((row.as_hero_image || '').removeWebp(),'411')"
                                class="img-responsive" />
                              <div class="slider_name">
                                <p># {{row.as_hero_title}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div :class="isAdd ? 'row mt-4' : 'row collapse mt-4' " id="collapseExample">
                          <div class="col-md-8 mb-3">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="panel_group">
                                  <div class="panel_heading">Hero Image Text</div>
                                  <div class="panel_body">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label class="control-label">Title <span class="text-danger mr5">*</span></label>
                                          <input v-model="row.as_hero_title" type="text" class="form-control"
                                            placeholder="Title">
                                          <VValidate name="Hero Title" v-model="row.as_hero_title" rules="required" />
                                        </div>
                                      </div>
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label class="control-label">Description</label>
                                          <textarea class="form-control" v-model="row.as_hero_desc" rows="4" placeholder="Description" rules="required"></textarea>
                                          <VValidate name="Hero Description" v-model="row.as_hero_desc" rules="required" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 mb-3">
                            <Uploader v-model="row.as_hero_image" type="hero" label="Photo" />
                            <VValidate name="Service Hero Image" v-model="row.as_hero_image" rules="required" />
                            <div class="form-group">
                              <label class="control-label">Alt Img <span class="text-danger mr5">*</span></label>
                              <input v-model="row.as_hero_alt" type="text" class="form-control"
                                placeholder="Alt Img">
                              <VValidate name="Service Hero Alt Image" v-model="row.as_hero_alt" rules="required" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-body">
                      <form action="#">
                        <div class="form-body">
                          <div class="row">
                            <div class="col-md-9 mt-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label class="control-label"> Service Name <span
                                        class="text-danger mr5">*</span></label>
                                    <div class="input-group">
                                      <input type="text" class="form-control" placeholder="Service Name" v-model="row.as_title" />
                                    </div>
                                    <VValidate name="Title" v-model="row.as_title" :rules="mrValidation.as_title" />
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-12 ">
                                      <div class="form-group">
                                        <label class="control-label">Service Short Description<span
                                            class="text-danger mr5">*</span></label>
                                        <textarea class="textarea_editor form-control" rows="5"
                                          placeholder="Service Short Desc" v-model="row.as_short_desc"></textarea>

                                        <VValidate name="Short Description" v-model="row.as_short_desc"
                                          :rules="mrValidation.as_short_desc" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-12 ">
                                      <div class="form-group">
                                        <label class="control-label">Service Description<span
                                            class="text-danger mr5">*</span></label>
                                        <CKEditor 
                                          :value.sync="row.as_desc"
                                        />
                                        <!-- <tinymce id="desc" @editorInit="tmEditorInit(row.as_desc)" v-model="row.as_desc"
                                          ref="tm"
                                          toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link image |'
                                          :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "200",menubar: false,branding:false}'>
                                        </tinymce>-->

                                        <VValidate name="Description" v-model="row.as_desc"
                                          :rules="mrValidation.as_desc" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <label class="control-label">Icon Service <span class="text-danger mr5">*</span></label>
                              <Uploader v-model="row.as_logo" type="icon_service" label="Icon" />
                              <VValidate name="Icon Service" v-model="row.as_logo" :rules="mrValidation.as_logo" />
                                
                              <div class="form-group mt-3">
                                <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                                <input type="text" v-model="row.as_alt" class="form-control" placeholder="Alt Image">
                                <VValidate name="Alt Image" v-model="row.as_alt" rules="required" />
                              </div>
                              <div class="form-group mt-3">
                                <label class="control-label">Background Color <span class="text-danger">*</span></label>
                                <select v-model="row.as_logo_back_color" class="form-control">
                                  <option value="Purple">Purple</option>
                                  <option value="Green">Green</option>
                                </select>
                                <VValidate name="Logo Back Color" v-model="row.as_logo_back_color" rules="required" />
                              </div>
                              <div class="form-group mt-3">
                                <label class="control-label">Status <span class="text-danger mr5">*</span> </label>
                                <div>
                                  <InputRadio v-model="row[statusKey]" name="status" option="Y" />
                                  <InputRadio v-model="row[statusKey]" name="status" option="N" />
                                </div>
                                <VValidate name="Status" v-model="row[statusKey]" :rules="mrValidation[statusKey]" />

                              </div>
                            </div>
                            <div class="col-sm-3">
                              <label class="control-label">Add Sub Service?</label>
                              <div class="row">
                                <div class="col-md-3">
                                  <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                                    <input type="checkbox" class="custom-control-input" id="chkPassport" value="check"
                                      :checked="isOpenFormSubService" @input="openFormSubService">
                                    <label class="custom-control-label" for="chkPassport" checked>Yes</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row" v-if="isOpenFormSubService">
                            <div class="col-md-12">
                              <h5 class="card-title m-t-20">Sub Service</h5>
                              <hr>
                              <template v-if="subServices.length > 0">
                                <div class="wrap_sub_service" v-for="(v,k) in subServices" :key="k">
                                  <div class="row">
                                    <div class="col-md-9">
                                      <div class="form-group">
                                        <label class="control-label">Sub Service Name ({{ k + 1 }})<span
                                            class="text-danger mr5">*</span></label>
                                        <input type="text" id="firstName" class="form-control"
                                          placeholder="Sub Service Name"
                                          @input="doAltSub(k)"
                                          v-model="v.asm_title">

                                        <VValidate :name="'Title Sub Service ('+ +(k+1) +')'"
                                          v-model="v.asm_title" rules="required" />
                                      </div>
                                      <div class="form-group">
                                        <label class="control-label">Sub Service Description ({{ k + 1 }})</label>
                                        <CKEditor 
                                          :value.sync="v.asm_desc"
                                        />
                                        <VValidate :name="'Description Sub Service ('+ +(k+1) +')'"
                                          v-model="v.asm_desc" rules="required" />
                                        <!-- <tinymce :id="'desc'+k" @editorInit="tmEditorInit(v.asm_desc,'reff'+k)" v-model="v.asm_desc" :ref="'reff'+k"
                                          toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link image |'
                                          :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "200",menubar: false,branding:false}'>
                                        </tinymce>-->
                                      </div>
                                    </div>
                                    <div class="col-md-3">
                                      <label class="control-label">Icon Service <span
                                          class="text-danger mr5">*</span></label>
                                      <Uploader v-model="v.asm_logo" type="icon_service" label="Icon" />
                                      
                                      <VValidate :name="'Icon Sub Services ('+ +(k+1) +')'"
                                        v-model="v.asm_logo" rules="required" />

                                      <div class="form-group mt-3">
                                        <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                                        <input type="text" v-model="v.asm_alt" class="form-control" placeholder="Alt Image">
                                        <VValidate :name="'Icon Alt Sub Service ('+ +(k+1) +')'" 
                                        v-model="v.asm_alt" rules="required" />
                                      </div>
                                      <div class="form-group mt-3">
                                        <label class="control-label">Background Color <span class="text-danger">*</span></label>
                                        <select v-model="v.asm_logo_back_color" class="form-control">
                                          <option value="Purple">Purple</option> 
                                          <option value="Green">Green</option>
                                        </select>
                                        <VValidate :name="'Logo Back Color Sub ('+ +(k+1) +')'" v-model="v.asm_logo_back_color" rules="required" />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="position-relative pt-5">
                                        <a @click.prevent="removeSubService(v.asm_id, v.id)" 
                                          class="remove_panel mt-3" 
                                          style="margin-top: 10em;"
                                          href="">Remove</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <div class="wrap_sub_service" v-if="subServices.length === 0">
                                <div class="row">
                                  <div class="col-md-9">
                                    <div class="form-group">
                                      <label class="control-label">Sub Service Name <span
                                          class="text-danger mr5">*</span></label>
                                      <input type="text" id="firstName" class="form-control"
                                        placeholder="Sub Service Name" v-model="formTitleSS">

                                      <VValidate :name="'Title Sub Service'" v-model="formTitleSS" rules="required" />
                                    </div>

                                    <div class="form-group">
                                      <label class="control-label">Sub Service Description</label>
                                      <CKEditor 
                                        :value.sync="formDescSS"
                                      />
                                      <!-- <tinymce id="sub_service" @editorInit="tmEditorInit(formDescSS)" v-model="formDescSS"
                                        ref="tm3"
                                        toolbar1='formatselect | bold italic underline | numlist bullist outdent indent | link image |'
                                        :other_options='{images_upload_url:apiUrl+"/ajax/upload?pageType=editor",content_style: "body { font-size: .875rem; font-family: Arial;}",height : "200",menubar: false,branding:false}'>
                                      </tinymce>-->
                                      <VValidate :name="'Desc Sub Service'" v-model="formDescSS" rules="required" />
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <label class="control-label">Icon Service <span
                                        class="text-danger mr5">*</span></label>
                                    <Uploader v-model="formLogoSS" type="icon_service" label="Icon" />
                                    <VValidate :name="'Icon Sub Service'" v-model="formLogoSS"
                                      rules="required" />
                                    
                                    <div class="form-group mt-3">
                                      <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                                      <input type="text" v-model="formAltSS" class="form-control" placeholder="Alt Image">
                                      <VValidate :name="'Icon Alt Sub Service'" 
                                      v-model="formAltSS" rules="required" />
                                    </div>

                                      <div class="form-group mt-3">
                                        <label class="control-label">Background Color <span class="text-danger">*</span></label>
                                        <select v-model="formBack" class="form-control">
                                          <option value="Purple">Purple</option>
                                          <option value="Green">Green</option>
                                        </select>
                                        <VValidate :name="'Logo Back Color Sub'" 
                                        v-model="formBack" rules="required" />
                                      </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="position-relative pt-5">
                                      <a @click.prevent="removeSubService('', '')" 
                                        class="remove_panel mt-3" 
                                        style="margin-top: 10em;"
                                        href="">Remove</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p class="mt-3">
                                <a class="link_text" @click.prevent="addSubServiceForm"
                                  style="cursor: pointer; text-decoration: underline;"> <i class="fas fa-plus"></i> Add
                                  Sub Service</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="tab-pane" id="seo" role="tabpanel">
                    <div class="card-body" v-if="isMeta">
                      
                      <div class="row" style="width: 100%">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Meta Title
                              <a class="help_info"
                                v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                >?</a> <span class="text-danger mr5">*</span></label>
                            <input v-model="row.as_meta_title" type="text" class="form-control"
                              placeholder="Meta Title">
                            <VValidate name="Meta Title" v-model="row.as_meta_title"
                              :rules="{required: 1, max: 70}" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Meta Keyword
                              <a class="help_info" v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                ?</a> <span class="text-danger mr5">*</span></label>
                            <input-tag v-model="row.as_meta_keyword"
                              placeholder="Type and press enter to Add">
                              <span class="d-none" slot="no-options" @click="$refs.select.open = false">
                              </span>
                            </input-tag>
                            <VValidate name="Meta Keyword" v-model="row.as_meta_keyword"
                              rules="required" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Meta Description
                              <a class="help_info"
                                v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                >
                                ?</a> <span class="text-danger mr5">*</span></label>
                            <textarea v-model="row.as_meta_description" class="form-control"
                              placeholder="e.g. Google are..." rows="4"></textarea>
                            <VValidate name="Meta Description" v-model="row.as_meta_description"
                              :rules="{required: 1, max: 160}" />
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    
                    <div class="card-body" v-else>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Meta Title <a
                                v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                <p>{{row.as_meta_title}}</p>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="" class="control-label">
                              Meta Keyword
                              <a class="help_info"
                                v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                ?
                              </a>
                              <span class="text-danger mr5">*</span>
                            </label>
                            <div></div>
                            <template v-for="(v,k) in row.as_meta_keyword" >
                              <label class="label label-success mr-1" :key="k">
                                {{v}}
                              </label>
                            </template>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="control-label">Meta Description <a
                                v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                            <p>{{row.as_meta_description}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <div class="form-actions text-right" v-if="tab === 'main' || tab === 'mainForm'">
                      <button type="button" @click="cancelButton()" class="btn mr-2 btn-rounded  btn-secondary">Cancel</button>
                      <button type="submit" @click="doValidate()" class="btn  btn-rounded  btn-info">Submit</button>
                    </div>
                    <div class="form-actions text-right" v-else>
                      <button type="button" @click="cancelButton()" class="btn mr-2 btn-rounded  btn-secondary">Cancel</button>
                      <template v-if="isMeta">
                        <button type="submit" @click="doValidate()" class="btn  btn-rounded  btn-info">Submit</button>
                      </template>
                      <template v-else>
                        <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
                      </template>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            </form>
          </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
button:disabled {
  cursor: auto;
}
</style>

<script>
  import GlobalVue from '@libs/Global'
  import PageTitle from '@view/include/PageTitle'
  import Gen from '@libs/Gen'
  import draggable from 'vuedraggable'
  import CKEditor from '@/components/CKEditor'

  let $ = global.jQuery
  let _ = global._

  export default {
    extends: GlobalVue,
    components: {
      PageTitle, draggable, CKEditor
    },
    data() {
      return {
        idKey: 'as_id',
        statusKey: "as_is_active",
        other_options: {
          menubar: false,
          content_style: "body { font-family: Arial; }"
        },
        section: {},
        rowSEO: {},
        rowSEOtemp: {},
        metaLatest: {},
        subServices: [],
        visibility: [],
        formDescSS: "",
        formBack: "Purple",
        formLogoSS: "",
        formAltSS: "",
        formTitleSS: "",
        isOpenFormSubService: false,
        isHero: false,
        tab: "main",
        isSearch: false,
        isMeta: false,
        isCheck: false,
        colour: '#000000',
        TabSeo : JSON.parse(localStorage.getItem("SEO")),
        sectionUpdateDate : ""
      }
    },
    mounted() {
      this.apiGet()
      this.apiAddition()
      this.apiGetHeroImage()
      this.apiGetSEO()

      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.val === "Y"){
        this.tab = 'SEO'
        
        $("#main").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav-dynamic").removeClass("active");
        $("#seo-nav-dynamic").addClass("active");
        
      }

      if(this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.valChild === "Y"){
        this.tab = 'SEOForm'
        
        $("#editor").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav").removeClass("active");
        $("#seo-nav").addClass("active");
        
      }


      if (this.isAdd) {
        this.dosetStatus();
        this.isMeta = true
      }

      if(this.isList){
        if(this.$route.query.search){
          if (this.filter.search.length <= 0) {
            this.isSearch = false
            $('#btn-search').attr("disabled", "disabled")
          } else {
            this.isSearch = true
            $('#btn-search').removeAttr("disabled")
          }  
        }
        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      }

      setTimeout(() => {
        this.doResetSEO()
        this.openFormSubService(this.$event)
        if(this.isAdd) {
          this.isOpenFormSubService = false;
        } 
        else {
          this.openFormSubService = false
          if(this.subServices.length > 0){
            this.isOpenFormSubService = true
          }
        }
      }, 300)


    },
    methods: {
      apiGet(params = {}){
        if(this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/"+this.modulePage+
          (this.pageSlug?"/"+this.pageSlug:"")+
          (this.pageId?"/"+this.pageId:""), 
          {
            params: Object.assign({}, this.apiParams, params.query||{})
          }
        ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
          if(!this.isList){
            this.setFocusForm()
          }
          if(params.callback) params.callback()
          this.setSection()
          this.sectionUpdateDate = res.data.section.sw_update_date
        }).catch(()=>{
          this.loadingOverlay = false
        })
      },
      editMeta(){
        this.isMeta = true
        this.isCheck = false
      },
      doSubmitSection() {
        var self = this
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateSection',
          }, this.section),
          (type, resp) => {
            if (type == 'success') {
              $(".swal2-confirm").click(function() {
                setTimeout(()=>{
                  document.body.scrollTop = document.documentElement.scrollTop = 0
                },500)
                self.apiGet()
              })
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VFormSection'
        )
      },
      cancelButton(){
        global.Swal.fire({
          icon: 'warning',
          title: 'Are You Sure to Cancel?',
          text: 'You will not able to recover this data.',
          showCancelButton: true,
          confirmButtonText: `Yes, Cancel`,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
        buttonsStyling: false,
        }).then(res => {  
          if (res.value) {
            let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
            localStorage.setItem("SEO", confSeo)
            this.$router.push({
              name: this.$route.name
            })
          }
        })
      },
      doAltSub(k){
        this.subServices[k].asm_alt = event.target.value
      },
      editAutoFill(){
        if(!this.isCheck){
          global.Swal.fire({
            icon: 'warning',
            title: 'Change back SEO Content to Auto-fill Mode?',
            text: 'Any changes you made will be lost!',
            showCancelButton: true,
            confirmButtonText: `Yes, change it!`,
            cancelButtonText: 'No Cancel',
            customClass: {
              confirmButton: 'btn btn-custom',
              cancelButton: 'btn btn-outline-custom ml-1',
            },
            buttonsStyling: false, 
          }).then(res => {  
            if (res.value) {
              this.isCheck = true
              this.rowSEO = {
                sss_id : this.rowSEO.sss_id,
                sss_title : this.metaLatest.as_meta_title,
                sss_desc : this.metaLatest.as_meta_description,
                sss_keyword : this.metaLatest.as_meta_keyword
              }
              $('#button-cancel').removeAttr('disabled')
            }
            else{
              this.isCheck = false
            }
          })
        }
        else{
          setTimeout(() => {
            this.rowSEO = {...this.rowSEOtemp}
          }, 0)    
        }
      },
      doSearch() {
        this.isSearch = true
      },
      clsSearch() {
        this.isSearch = false
        setTimeout(() => {
          $('#btn-search').attr("disabled", "disabled")
        }, 0)
      },
      apiGetSEO() {
        if (this.pageSlug) this.loadingOverlay = true

        this.data.data = false
        Gen.apiRest(
          "/get/" + "StaticSEO/3"
        ).
        then(res => {
            this.loadingOverlay = false
            this.rowSEO = res.data.row;
            this.rowSEOtemp = res.data.row;
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      
      doBackSuccessMeta(){
        this.tab = "main"
        $("#seo").removeClass("active").removeClass("show");
        $("#main").addClass("active").addClass("show");

        $("#seo-nav-dynamic").removeClass("active");
        $("#main-nav-dynamic").addClass("active");
        
        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      },

      doSubmitSeo(IdFormSeo = 'VFormSeo') {
        this.rowSEO.sss_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + "StaticSEO",
          _.assign({
            type: 'update',
            id: this.rowSEO.sss_id
          }, this.rowSEO),
          (type, resp) => {
            if (type == 'success') {
              this.isMeta = false
              this.doBackSuccessMeta()
              this.rowSEO = resp.data
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IdFormSeo
        )
      },
      openHero() {
        this.isHero = !this.isHero;
      },
      apiAddition() {
        if (this.pageSlug) this.loadingOverlay = true

        this.data.data = false
        Gen.apiRest("/get/Addition")
          .then(res => {
            this.loadingOverlay = false

            this.visibility = res.data.visibilityServices
          })
          .catch(() => this.loadingOverlay = false)
      },
      apiGetHeroImage() {
        if (this.pageSlug) this.loadingOverlay = true

        this.data.data = false
        Gen.apiRest(
          "/get/" + "HeroImageServices"
        ).
        then(res => {
            this.loadingOverlay = false

            setTimeout(() => {
              this.row = Object.assign({}, this.row, res.data.row)
            }, 300)

          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      changeStatus(e, section) {
        let status = e.target.checked ? "active" : "inactive"
        Gen.apiRest(
          '/do/Addition', {
            data: {
              type: "status",
              status: status,
              title: "Services",
              section: section,
              update_by: this.user.id
            }
          },
          "POST"
        )

      },
      checkStatus(section) {
        let check = this.visibility.find(vis => vis.sw_title === section && vis.sw_description === "active")
        return check
      },

      doSubmitHero() {
        this.row.ahi_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateHeroImage',
            id: this.row.ahi_id
          }, this.row),
          (type, resp) => {
            if (type == 'success') {
              this.isHero = false
              this.row = resp.data
              this.$router.push({
                name: this.$route.name
              })
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST','VFormHero' 
        )
      },

      openFormSubService(e) {
        this.isOpenFormSubService = e.target.checked
      },
      addSubServiceForm() {
        let obj = [{
          id: Math.floor(Math.random() * 12345672),
          asm_id: "",
          asm_create_by: this.user.id,
          asm_update_by: this.user.id,
          asm_update_date: "",
          asm_create_date: "",
          asm_desc: "",
          asm_logo: "",
          asm_logo_back_color: this.formBack,
          asm_service: this.row.as_id ? "" : "",
          asm_title: "",
        }]

        this.subServices.length === 0 ?
          this.subServices = [{
            id: Math.floor(Math.random() * 12345672),
            asm_id: "",
            asm_desc: this.formDescSS,
            asm_title: this.formTitleSS,
            asm_logo: this.formLogoSS,
            asm_alt: this.formAltSS,
            asm_logo_back_color: this.formBack,
            asm_create_date: "",
            asm_create_by: this.user.id
          }, ...obj] :
          this.subServices = [...this.subServices, ...obj]

      },
      
      submitService(slug) {
        let paramsData = this.subServices.length === 0 ? [{
          id: Math.floor(Math.random() * 12345672),
          asm_id: "",
          asm_desc: this.formDescSS,
          asm_title: this.formTitleSS,
          asm_logo: this.formLogoSS,
          asm_alt: this.formAltSS,
          asm_logo_back_color: this.formBack,
          asm_create_by: this.user.id,
          asm_create_date: "",
          asm_update_by: this.user.id,
          asm_update_date: "",
        }] : this.subServices

        var isAddServ = slug == "add" ? true : false
        
        if (slug === "add") {
          return this.doSubmit(
            "/do/" + this.modulePage,
            _.assign({
              type: 'add',
              id: this.row.ahi_id
            }, this.row, {
              subServices: paramsData
            }),
            (type, resp) => {
              if (type == 'success') {
                this.$router.push({
                  name: this.$route.name
                })
                this.isMeta = false
                this.apiGet()
                this.apiGetHeroImage()
                this.apiAddition()

                this.formTitleSS = ''
                this.formLogoSS = ''
                this.formAltSS = ''
                this.formDescSS = ''
                this.formBack = 'Purple'
              } else {
                if (resp.response.status == 422) {
                  if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                    var msg = ""
                    $.each(Object.keys(resp.response.data), (i, v) => {
                      msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                    })
                    return global.Swal.fire("Blocked With Validation!", msg)
                  } else {
                    return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                  }
                } else if (resp.response.status == 400) {
                  return global.Swal.fire({
                    title: resp.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                  })
                }
              }
            },
            'POST','VForm',isAddServ
          )
        } else {
          return this.doSubmit(
            "/do/" + this.modulePage,
            _.assign({
              type: 'update',
              as_id: this.row.as_id
            }, this.row, {
              subServices: paramsData
            }),
            (type, resp) => {
              if (type == 'success') {
                this.$router.push({
                  name: this.$route.name
                })

                this.isMeta = false

                this.apiGet()
                this.apiGetHeroImage()
                this.apiAddition()

                this.formTitleSS = ''
                this.formLogoSS = ''
                this.formAltSS = ''
                this.formDescSS = ''
                this.formBack = 'Purple'
              } else {
                if (resp.response.status == 422) {
                  if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                    var msg = ""
                    $.each(Object.keys(resp.response.data), (i, v) => {
                      msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                    })
                    return global.Swal.fire("Blocked With Validation!", msg)
                  } else {
                    return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                  }
                } else if (resp.response.status == 400) {
                  return global.Swal.fire({
                    title: resp.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                  })
                }
              }
            },
            'POST','VForm',isAddServ
          )
        }
      },
      removeSubService(asm_id, id) {
        if (asm_id === "" && id === "" && this.subServices.length === 0) {
          this.isOpenFormSubService = false
          this.formDescSS = ""
          this.formTitleSS = ""
          this.formLogoSS = ""
          this.formAltSS = ""
        }

        if (asm_id === "" && id && this.subServices.length > 0) {
          this.subServices = this.subServices.filter(ss => ss.id !== id)

          if (this.subServices.length === 0) {
            this.isOpenFormSubService = false
            this.formDescSS = ""
            this.formTitleSS = ""
            this.formLogoSS = ""
            this.formAltSS = ""
          }
        }

        if (asm_id && id === undefined && this.subServices.length > 0) {
          Gen.apiRest(
              '/do/' + this.modulePage, {
                data: {
                  type: "removeSubService",
                  id: asm_id
                }
              },
              "POST"
            )
            .then(() => {
              this.subServices = this.subServices.filter(ss => ss.asm_id !== asm_id)

              if (this.subServices.length === 0) {
                this.subServices = []
              }
            })
        }
      },
      dosetStatus() {
        setTimeout(() => {
          this.row[this.statusKey] = "Y"
        }, 300)
      },
      startDrag(){
        $(".sortable-fallback").removeAttr("style")
        $(".sortable-fallback").css("display","none")
      },
      endDrag() {
        const page = this.$route.query.page || 1
        Gen.apiRest(
          "/do/" + 'Services', {
            data: {
              type: 'sort',
              data: this.data.data,
              page: page
            }
          },
          "POST"
        )
      },
      changeTab(tab){
        this.tab = tab
        
        if(this.tab === "SEO"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "Y"})
          localStorage.setItem("SEO", confSeo)
        }
        else if(this.tab === "SEOForm"){
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N", valChild: "Y"})
          localStorage.setItem("SEO", confSeo)
        }
        else{
          let confSeo = JSON.stringify({ menu : `${this.$route.name}`, val : "N"})
          localStorage.setItem("SEO", confSeo)
        }

      },
      doValidate(){
        if(this.row.as_meta_title.length > 70 || this.row.as_meta_description.length > 160 || !this.row.as_meta_title || !this.row.as_meta_description || !this.row.as_meta_keyword){
          $("#editor").removeClass("active");
          $("#seo").addClass("active");

          $("#main-nav").removeClass("active");
          $("#seo-nav").addClass("active");

          this.tab = 'SEO'
          return;
        }
        else if(!this.row.as_title || !this.row.as_short_desc || !this.row.as_desc || !this.row.as_logo || !this.row.as_alt || !this.row.as_is_active || !this.row.as_hero_image || !this.row.as_hero_title || !this.row.as_hero_alt || !this.row.as_hero_desc){

          if(!this.row.as_hero_image || !this.row.as_hero_title || !this.row.as_hero_alt || !this.row.as_hero_desc){
            $(window).scrollTop(0)
          }
          
          $("#editor").addClass("active");
          $("#seo").removeClass("active");
          
          $("#main-nav").addClass("active");
          $("#seo-nav").removeClass("active");

          this.tab = 'main'
          
          return;
        }
        else{
          if(this.isOpenFormSubService){
            if(this.subServices.length > 0){
              for(let i = 0; i < this.subServices.length; i++){
                if(!this.subServices[i].asm_title || !this.subServices[i].asm_desc || !this.subServices[i].asm_logo || !this.subServices[i].asm_alt){
                  console.log("please validate first")
                }
                else{
                  this.submitService(this.$route.params.pageSlug)
                }
              }
            }
            else{
              if(!this.formTitleSS || !this.formDescSS || !this.formLogoSS || !this.formAltSS){
                console.log("please validate first")              
              }
              else{
                this.submitService(this.$route.params.pageSlug)
              }
            }
          }
          else{
            this.submitService(this.$route.params.pageSlug)
          }
        }
      },
      doResetSEO(){
        setTimeout(() => {
          this.rowSEO = {...this.rowSEOtemp}
        }, 0)    
        this.isCheck = false
        $('#button-cancel').attr('disabled', 'disabled')
      },
      checkDisabledSeo() {
        $('#button-cancel').removeAttr('disabled')
      },
      checkDisabled() {
        if (this.filter.search.length <= 0) {
          $('#btn-search').attr("disabled", "disabled")
        } else {
          $('#btn-search').removeAttr("disabled")
        }
      },
      setSection(){
        const sectionVal = JSON.parse(this.section.sw_description)
        this.section = {
          title: this.section.sw_title,
          desc: sectionVal.desc,
          button_label: sectionVal.button_label,
          button_link: sectionVal.button_link
        }
      }
    },
    watch: {
      $route() {
        this.apiGet()
        this.apiGetHeroImage()
        this.apiAddition()
        this.apiGetSEO()

        this.tab = 'main'

        if (this.isAdd) {
          this.isMeta = true
        }
        if (this.isList){        
          let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
          localStorage.setItem("SEO", confSeo)
          this.isMeta = false          
        }

        if (!this.isList) {
          this.isSearch = false
          this.filter = {}
        } else {
          this.filter = {
            ...this.filter
          }
        }
        
        setTimeout(() => {
          this.doResetSEO()
          if(this.isAdd) {
            this.isOpenFormSubService = false;
          } 
          
        }, 300)

      },
      'subServices'(){
        if(!this.isAdd){
          this.isOpenFormSubService = false
          if(this.subServices.length > 0){
            this.isOpenFormSubService = true
          }
        }
      },
      'row.ahi_title'(v){
        this.row.ahi_alt = v
      },
      'row.as_hero_title'(v){
        this.row.as_hero_alt = v
      },
      'row.as_title'(v){
        if(this.isAdd){
          this.row.as_meta_title = v
          if(v){
            this.row.as_meta_keyword = [v]
          }
          else{
            this.row.as_meta_keyword = ''
          }
        }
        this.row.as_alt = v
      },
      'row.as_short_desc'(v){
        if(this.isAdd){
          this.row.as_meta_description = v
        }
      },
      'formTitleSS'(v){
        this.formAltSS = v
      }
    }
  }
</script>